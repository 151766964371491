import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { films, series, pubs } from "../productions";
import { useLanguage } from '../LanguageContext'; // Assurez-vous d'utiliser le bon chemin
import 'lightbox.js-react/dist/index.css'
import {SlideshowLightbox, initLightboxJS} from 'lightbox.js-react'

const productions = [...films, ...series, ...pubs];

function ProductionDetail() {
  const { id } = useParams();
  const { language } = useLanguage();
  
  const production = productions.find((prod) => prod.id === id);

  useEffect(() => {
    initLightboxJS("05BC-6058-5FA1-3498", "individual");
  }, []);

  if (!production) return <div>{language === 'fr' ? 'Production non trouvée' : 'Production not found'}</div>;

  const isYouTubeLink = (url) => {
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
    return youtubeRegex.test(url);
  };

  const getYouTubeEmbedUrl = (url) => {
    const urlObj = new URL(url);
    if (urlObj.hostname === "youtu.be") {
      return `https://www.youtube.com/embed/${urlObj.pathname.slice(1)}`;
    } else if (urlObj.hostname.includes("youtube.com")) {
      return `https://www.youtube.com/embed/${urlObj.searchParams.get("v")}`;
    }
    return null;
  };

  return (
    <div className="detail-container">
      {production.lien && isYouTubeLink(production.lien) && (
        <div className="video-container">
          <iframe
            src={getYouTubeEmbedUrl(production.lien)}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      )}
      <div className="infos-container">
        <div className="texte-container">
          {production.annee && <p className="categorie annee">{production.annee}</p>}
          {production.job && (
            <div>
              <p className="categorie">{language === 'fr' ? 'Directrice artistique' : 'Art director'}</p>
              <p className="infos"><strong>Colombe Raby</strong></p>
            </div>
          )}
          {production.realisateur && (
            <div>
              <p className="categorie">{language === 'fr' ? 'Réalisateur' : 'Director'}</p>
              <p className="infos"><strong>{production.realisateur}</strong></p>
            </div>
          )}
          {production.acteurs && (
            <div>
              <p className="categorie">{language === 'fr' ? 'Acteurs.ices' : 'Actors'}</p>
              <p className="infos"><strong>{production.acteurs}</strong></p>
            </div>
          )}
          {production.production && production.production.length > 0 && (
            <div>
              <p className="categorie">{language === 'fr' ? 'Production' : 'Production'}</p>
              {production.production.map((prod, index) => (
                <strong key={index}><p className="infos">{prod}</p></strong>
              ))}
            </div>
          )}
          {production.distribution && production.distribution.length > 0 && (
            <div>
              <p className="categorie">{language === 'fr' ? 'Distribution' : 'Distribution'}</p>
              {production.distribution.map((dist, index) => (
                <strong key={index}><p className="infos">{dist}</p></strong>
              ))}
            </div>
          )}
          {production.nominations && production.nominations.length > 0 && (
            <div>
              {production.nominations.map((nomination, index) => (
                <i key={index}><p className="categorie">{nomination}</p></i>
              ))}
            </div>
          )}
        </div>
        {production.album && production.album.length > 0 && (
          <SlideshowLightbox className="album-container" showThumbnails={true}>
            {production.album.map((pict, index) => (
              <img src={"../" + pict} alt={`Image ${index + 1}`} />
            ))}
          </SlideshowLightbox>
        )}
      </div>
    </div>
  );
}

export default ProductionDetail;
