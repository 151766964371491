import React, { useEffect, useState } from "react";
import Production from "../components/Production";
import home from "../home";

function ajouterProduction(production) {
  return (
    <Production 
      key={production.id}
      id={production.id}
      img={production.img}
      titre={production.titre}
      job={production.job}
      className="home-item"
      classTitre="titreHomeProduction"
    />
  );
}

function Home() {
  return (
    <div>
      {home.map(ajouterProduction)}
    </div> 
  )
}

export default Home;