import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Header";
import Home from "../pages/Home";
import Contact from "../pages/Contact";
import NoPage from "../pages/NoPage";
import Productions from "../pages/Productions";
import ProductionDetail from "../pages/ProductionDetail";
import EnProduction from "../pages/EnProduction"
import Footer from "./Footer";

function App() {
  return (
    <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/productions" element={<Productions />}>
            <Route path=":category" element={<Productions />} />
          </Route>
          <Route path="/productions/en-production" element={<EnProduction />} />
          <Route path="/production/:id" element={<ProductionDetail />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
        <Footer />
    </Router>
  );
}

export default App;
