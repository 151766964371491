import React, { useState } from 'react';
import { useLanguage } from '../LanguageContext';
import { Link } from 'react-router-dom';

function Production(props) {
  const { language } = useLanguage();
  const [isHovered, setIsHovered] = useState(false);

  const handleTouchStart = () => {
    setIsHovered(true);
  };

  const handleTouchEnd = () => {
    setIsHovered(false);
  };

  return (
    <Link
      to={`/production/${props.id}`}
      className={`${props.className} ${isHovered ? 'hovered' : ''}`}
      onClick={() => window.scrollTo(0, 0)}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <img className="imgProduction" src={"../"+props.img} alt={props.titre} />
      <div className={`containerProduction ${isHovered ? 'hovered' : ''}`}>
        <div className={props.classTitre}>
        {props.titre}
        {props.job && (
          <p className="smallTitreProduction">
            {language === 'fr' ? 'Directrice artistique' : 'Art director'}
          </p>
        )}
        </div>
      </div>
    </Link>
  );
}

export default Production;
