import React, { createContext, useState, useContext } from 'react';

const LanguageContext = createContext();

export const useLanguage = () => {
  return useContext(LanguageContext);
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('fr'); // langue par défaut

  const switchToEnglish = () => {
    setLanguage('en');
  };

  const switchToFrench = () => {
    setLanguage('fr');
  };

  return (
    <LanguageContext.Provider value={{ language, switchToEnglish, switchToFrench }}>
      {children}
    </LanguageContext.Provider>
  );
};
