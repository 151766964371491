import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLanguage } from '../LanguageContext';

const Header = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const navigate = useNavigate();
  const { language, switchToEnglish, switchToFrench } = useLanguage();

  useEffect(() => {
    const checkIfTouchDevice = () => {
      const hasTouch = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
      setIsTouchDevice(hasTouch);
    };

    checkIfTouchDevice();

    window.addEventListener('pointerdown', checkIfTouchDevice);

    return () => {
      window.removeEventListener('pointerdown', checkIfTouchDevice);
    };
  }, []);

  const handleMouseEnter = () => {
    if (!isTouchDevice) {
      setDropdownOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isTouchDevice) {
      setDropdownOpen(false);
    }
  };

  const toggleOffcanvas = () => {
    setOffcanvasOpen(!isOffcanvasOpen);
  };

  const handleSwitchToEnglish = () => {
    switchToEnglish();
    const path = window.location.pathname;
    navigate(path);
  };

  const handleSwitchToFrench = () => {
    switchToFrench();
    const path = window.location.pathname.replace;
    navigate(path);
  };

  return (
    <>
      <header>
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
          <div className="container-fluid">
            <a className="nomAccueil" href="/">
              <p className="light lessSpace">Colombe Raby</p>
              <div className="titreAccueil">
                <p className="light">CONCEPTRICE VISUELLE</p>
                <div className="separateur"></div>
                <p className="light">PRODUCTION DESIGNER</p>
              </div>
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="offcanvas offcanvas-end text-bg-dark" tabIndex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
              <div className="offcanvas-header">
                <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>

              <div className="offcanvas-body">
                <ul className="listeAccueil navbar-nav flex-grow-1" id="prod-list">
                  <li
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{ position: 'relative' }}
                    data-bs-dismiss="offcanvas"
                  >
                    <Link className="underline-hover" to="/productions">
                      {language === 'fr' ? 'Productions' : 'Productions'}
                    </Link>

                    <ul className={`dropdown ${(isDropdownOpen && !isTouchDevice) || isTouchDevice ? 'open' : 'closed'}`}>
                      <li data-bs-dismiss="offcanvas">
                        <Link className="underline-hover" to="/productions/films" onClick={() => { toggleOffcanvas(); window.scrollTo(0, 0); }}>
                          {language === 'fr' ? 'Films' : 'Films'}
                        </Link>
                      </li>
                      <li data-bs-dismiss="offcanvas">
                        <Link className="underline-hover" to="/productions/series" onClick={() => { toggleOffcanvas(); window.scrollTo(0, 0); }}>
                          {language === 'fr' ? 'Séries' : 'Series'}
                        </Link>
                      </li>
                      <li data-bs-dismiss="offcanvas">
                        <Link className="underline-hover" to="/productions/publicites" onClick={() => { toggleOffcanvas(); window.scrollTo(0, 0); }}>
                          {language === 'fr' ? 'Publicités' : 'Commercials'}
                        </Link>
                      </li>
                      <li data-bs-dismiss="offcanvas">
                        <Link className="underline-hover" to="/productions/en-production" onClick={() => { toggleOffcanvas(); window.scrollTo(0, 0); }}>
                          {language === 'fr' ? 'En production' : 'In production'}
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li data-bs-dismiss="offcanvas">
                    <a
                      className="underline-hover"
                      href={language === 'fr' ? "/Colombe_Resume_FR.pdf" : "/Colombe_Resume_EN.pdf"}
                      download
                      onClick={toggleOffcanvas}
                    >
                      {language === 'fr' ? 'CV' : 'Resume'}
                    </a>
                  </li>

                  <li data-bs-dismiss="offcanvas">
                    <Link className="underline-hover" to="/contact" onClick={toggleOffcanvas}>
                      {language === 'fr' ? 'Contact' : 'Contact'}
                    </Link>
                  </li>

                  <li className="languesAccueil nav-item">
                    <a data-bs-dismiss="offcanvas" onClick={handleSwitchToFrench} className="lang-button underline-hover">
                      FR
                    </a>
                    <span> / </span>
                    <a data-bs-dismiss="offcanvas" onClick={handleSwitchToEnglish} className="lang-button underline-hover">
                      EN
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;