const filmsEnProd = [
  { 
    id: "ou-vont-les-ames",  
    titre: "Où vont les âmes?",
    annee: ["En production", "In production"],
    realisateur: "Brigitte Poupart",
    acteurs: "Monia Chokri, Julianne Côté, Sara Montpetit",
    production: ["Bravo Charlie"]
  },
  { 
    id: "in-cold-light", 
    titre: "In Cold Light",
    annee: ["En post-production", "In post-production"],
    realisateur: "Maxime Giroux",
    acteurs: "Maika Monroe, Troy Kotsur",
    production: ["Peripheria", "Lithium Studios Productions"]
  }
]

export default filmsEnProd;