import React from "react";
import filmsEnProd from "../enProduction";
import { useLanguage } from '../LanguageContext'; // Assurez-vous d'utiliser le bon chemin

function ajouterEnProduction(production, language) {
  return (
    <div className="detail-en-prod-container" key={production.titre}>
      <div className="infos-en-prod-container">
        {production.titre && <h1>{production.titre}</h1>}
        {production.annee && <p className="categorie annee">{language === 'fr' ? production.annee[0] : production.annee[1]}</p>}
      </div>

      <div>
        {production.realisateur && (
          <div>
            <p className="categorie">{language === 'fr' ? 'Réalisateur' : 'Director'}</p>
            <p className="infos"><strong>{production.realisateur}</strong></p>
          </div>
        )}
        {production.acteurs && (
          <div>
            <p className="categorie">{language === 'fr' ? 'Acteurs.ices' : 'Starring'}</p>
            <p className="infos"><strong>{production.acteurs}</strong></p>
          </div>
        )}
        {production.production && production.production.length > 0 && (
          <div>
            <p className="categorie">{language === 'fr' ? 'Production' : 'Production'}</p>
            {production.production.map((prod, index) => (
              <strong key={index}><p className="infos">{prod}</p></strong>
            ))}
          </div>
        )}
      </div>
    </div>      
  );
}

function EnProduction() {
  const { language } = useLanguage();

  return (
    <div className="en-production-grid">
      {filmsEnProd.map(production => ajouterEnProduction(production, language))}
    </div> 
  );
}

export default EnProduction;
