import React from "react";
import { useLanguage } from '../LanguageContext'; // Assurez-vous d'utiliser le bon chemin

function Contact() {
  const { language } = useLanguage();

  return (
    <div className="contact-container">
      <div className="contact-top">
        <img src="img/Contact.jpg" alt="Colombe" className="contactColombe" />
        <div className="contactInfos">
          <p className="contactTitre">
            {language === 'fr' ? 'Conceptrice Visuelle' : 'Visual Designer'}
          </p>
          <p className="contactGuilde">
            {language === 'fr' ? 'Membre de la Guilde canadienne des réalisateurs #6049' : 'Directors Guild of Canada member #6049'}
          </p>
          <a className="contactMail" href="mailto:colomberaby@gmail.com">colomberaby@gmail.com</a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
