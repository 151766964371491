import React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App.jsx";
import { LanguageProvider } from "./LanguageContext.jsx";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <LanguageProvider>
      <App />
    </LanguageProvider>
  </React.StrictMode>
);
