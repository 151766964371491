const films = [
  { 
    id: "simple-comme-sylvain", 
    img: "img/Films/Simple_comme_Sylvain/SCS1.jpg", 
    titre: "Simple comme Sylvain",
    lien: "https://youtube.com/watch?v=LJEGwAIcKIY",
    annee: "2023",
    realisateur: "Monia Chokri",
    acteurs: "Magalie Lépine Blondeau, Pierre-Yves Cardinal, Francis-William Rhéaume, Monia Chokri",
    production: ["Metafilms", "MK Productions"],
    distribution: [""],
    album: [
      "img/Films/Simple_comme_Sylvain/SCS2.jpg",
      "img/Films/Simple_comme_Sylvain/SCS3.jpg",
      "img/Films/Simple_comme_Sylvain/SCS4.jpg"
    ]
  },
  { 
    id: "tu-ne-sauras-jamais", 
    img: "img/Films/tu_ne_sauras_jamais/TNSJ1.jpg", 
    titre: "Tu ne sauras jamais",
    lien: "https://youtu.be/tH7Q_MRxacs",
    annee: "2023",
    realisateur: "Robin Aubert",
    acteurs: "Martin Naud, Sarah Keita, Jean-Marie Lapointe",
    production: ["Bravo Charlie"],
    distribution: ["Axia Films"],
    album: [
      "img/Films/tu_ne_sauras_jamais/TNSJ1.jpg",
      "img/Films/tu_ne_sauras_jamais/TNSJ2.jpg",
      "img/Films/tu_ne_sauras_jamais/TNSJ3.jpg"
    ]
  },
  { 
    id: "babysitter", 
    img: "img/Films/Babysitter/BB5.jpg", 
    titre: "Babysitter",
    lien: "https://youtu.be/fJkD2dZOR5I",
    annee: "2020",
    realisateur: "Monia Chokri",
    acteurs: "Monia Chokri, Nadia Tereszkiewicz, Patrick Hivon, Steve Laplante",
    production: ["Amérique Film"],
    distribution: ["BAC Films"],
    nominations: 
      [
        "Winner Canadian Film Fest Award : Best Set Design"
      ],
    album: [
      "img/Films/Babysitter/BB1.jpg",
      "img/Films/Babysitter/BB2.jpg",
      "img/Films/Babysitter/BB3.jpg",
      "img/Films/Babysitter/BB4.jpg",
      "img/Films/Babysitter/BB5.jpg",
      "img/Films/Babysitter/BB6.jpg"
    ]
  },
  { 
    id: "matthias-et-maxime", 
    img: "img/Films/Matthias_Maxime/MM.jpg", 
    titre: "Matthias & Maxime",
    lien: "https://youtu.be/BJ-5OujmDEw",
    annee: "2019",
    realisateur: "Xavier Dolan",
    acteurs: "Xavier Dolan, Gabriel D'Almeida Freitas, Anne Dorval, Pier-Luc Funk",
    production: ["Sons of Manual", "Metafilms"],
    distribution: ["Les Films Séville", "Diaphana Distribution"],
    album: [
      "img/Films/Matthias_Maxime/MM1.jpg",
      "img/Films/Matthias_Maxime/MM2.jpg",
      "img/Films/Matthias_Maxime/MM3.jpg",
      "img/Films/Matthias_Maxime/MM4.jpg",
      "img/Films/Matthias_Maxime/MM5.jpg",
      "img/Films/Matthias_Maxime/MM6.jpg"
    ]
    
  }, 
  { id: "the-life-and-death-of-john-f-donovan", 
    img: "img/Films/John_F_Donovan/JOHN.jpg", 
    titre: "The Death and Life of John F. Donovan",
    lien: "https://youtu.be/PmjRhEXLReQ",
    annee: "2016",
    realisateur: "Xavier Dolan",
    acteurs: "Kit Harrington, Natalie Portman, Jacob Tremblay, Susan Sarandon",
    production: ["Sons of Manual", "Warp Films", "Lyla Films"],
    distribution: ["Les Films Séville", "Séville International", "Mars Distribution"],
    album: [
      "img/Films/John_F_Donovan/JO1.jpg",
      "img/Films/John_F_Donovan/JO3.jpg",
      "img/Films/John_F_Donovan/JO5.jpg",
      "img/Films/John_F_Donovan/JOHN.jpg",
      "img/Films/John_F_Donovan/JOHN1.jpg",
      "img/Films/John_F_Donovan/JOHN2.jpg"
    ]
  }, 
  { id: "hello", 
    img: "img/Films/Hello/HELLO.jpg", 
    titre: "Hello",
    lien: "https://youtu.be/YQHsXMglC9A",
    annee: "2015",
    realisateur: "Xavier Dolan",
    acteurs: "Adele, Tristan Wilds",
    nominations: ["Nominee MTV Music Video Awards : Best Art Direction"],
    album: [
      "img/Films/Hello/Adele2.jpg",
      "img/Films/Hello/Adele3.jpg",
      "img/Films/Hello/Adele1.jpg",
      "img/Films/Hello/HE1.jpg",
      "img/Films/Hello/HE2.jpg",
      "img/Films/Hello/HE3.jpg"
    ]
  },
  { id: "juste-la-fin-du-monde", 
    img: "img/Films/Juste_la_fin_du_monde/END.jpg", 
    titre: "Juste la Fin du Monde",
    lien: "https://youtu.be/eSIXbNuCFNY",
    annee: "2015",
    realisateur: "Xavier Dolan",
    acteurs: "Gaspard Ulliel, Marion Cotillard, Vincent Cassel, Léa Seydoux",
    production: ["Sons of Manual", "Metafilms", "MK2"],
    distribution: ["Les Films Séville", "Diaphana Films"],
    album: [
      "img/Films/Juste_la_fin_du_monde/END.jpg",
      "img/Films/Juste_la_fin_du_monde/JLFDM1.jpg",
      "img/Films/Juste_la_fin_du_monde/JLFDM3.jpg",
      "img/Films/Juste_la_fin_du_monde/JUST1.jpg",
      "img/Films/Juste_la_fin_du_monde/JUST2.jpg",
      "img/Films/Juste_la_fin_du_monde/JUST3.jpg"
    ]
  },
  { id: "le-fils-de-jean", 
    img: "img/Films/Fils_de_Jean/JEAN1.jpg", 
    titre: "Le Fils de Jean",
    lien: "https://youtu.be/gjoebPwNMoQ",
    annee: "2015",
    realisateur: "Philippe Lioret",
    acteurs: "Gabriel Arcand, Pierre Deladonchamps",
    production: ["Item 7", "Fin Août Productions"],
    distribution: ["Le Pacte", "Les Films Séville"],
    album: [
      "img/Films/Fils_de_Jean/JEAN2.jpg",
      "img/Films/Fils_de_Jean/JEAN3.jpg",
      "img/Films/Fils_de_Jean/JEAN4.jpg"
    ]
  },
  { id: "mommy", 
    img: "img/Films/Mommy/MOM.jpg", 
    titre: "Mommy",
    lien: "https://youtu.be/d7rtSqI0ZeA",
    annee: "2019-2020",
    realisateur: "Xavier Dolan",
    acteurs: "Anne Dorval, Antoine Olivier Pilon, Suzanne Clément",
    production: ["Sons of Manual", "Metafilms"],
    distribution: ["Les Films Séville", "MK2", "Diaphana"],
    nominations: 
      [
        "Winner DGC Team Award : Feature Film", 
        "Nominee Canadian Screen Awards : Achievement in Art", 
        "Direction / Production Design Nominee Jutra Awards : Best Art Direction"
      ],
    album: [
      "img/Films/Mommy/MO1.jpg",
      "img/Films/Mommy/MO2.jpg",
      "img/Films/Mommy/MO3.jpg",
      "img/Films/Mommy/MO5.jpg",
      "img/Films/Mommy/MO6.jpg",
      "img/Films/Mommy/MO8.jpg",
      "img/Films/Mommy/Mom.jpg",
      "img/Films/Mommy/Mom1.jpg",
      "img/Films/Mommy/Mom2.jpg"
    ]
  }, 
  { id: "dont-sing", 
    img: "img/Films/Dont_sing/DA3.jpg", 
    titre: "Data Don't Sing",
    lien: "https://www.youtube.com/watch?v=37eEUsd1ASA",
    annee: "2015",
    realisateur: "David Bertram",
    album: [
      "img/Films/Dont_sing/DA1.jpg",
      "img/Films/Dont_sing/DA2.jpg",
      "img/Films/Dont_sing/DA3.jpg",
      "img/Films/Dont_sing/DA4.jpg",
      "img/Films/Dont_sing/DA5.jpg",
      "img/Films/Dont_sing/DONT.jpg"
    ]
  },
  { id: "anna", 
    img: "img/Films/Anna/ANNA1.jpg", 
    titre: "Mindscape",
    lien: "https://youtu.be/ZUimeHu2iZo",
    annee: "2012",
    realisateur: "Jorge Dorado",
    acteurs: "Mark Strong, Taissa Farmiga, Brian Cox",
    production: ["Reprise Film", "The Safran Company"],
    distribution: ["Warner Bros."] ,
    album: [
      "img/Films/Anna/ANNA1.jpg",
      "img/Films/Anna/ANNA2.jpg",
      "img/Films/Anna/ANNA3.jpg"
    ]
  },
  { id: "tom-a-la-ferme", 
    img: "img/Films/Tom_a_la_ferme/TOM.jpg", 
    titre: "Tom à la ferme",
    lien: "https://youtu.be/8th6_k1bW2Q",
    annee: "2012",
    realisateur: "Xavier Dolan",
    acteurs: "Xavier Dolan, Pierre-Yves Cardinal, Lise Roy",
    production: ["Sons of Manual", "Metafilms"],
    distribution: ["Les Films Séville", "MK2", "Diaphana"],
    album: [
      "img/Films/Tom_a_la_ferme/TOM.jpg",
      "img/Films/Tom_a_la_ferme/TOM1.jpg",
      "img/Films/Tom_a_la_ferme/TOM3.jpg",
      "img/Films/Tom_a_la_ferme/TOM4.jpg",
      "img/Films/Tom_a_la_ferme/TOM5.jpg",
      "img/Films/Tom_a_la_ferme/TOM6.jpg"
    ]
  }, 
  { id: "vic-plus-flo-ont-vu-un-ours", 
    img: "img/Films/Vic_plus_Flo/VIC.jpg", 
    titre: "Vic et Flo ont vu un ours",
    lien: "https://youtu.be/aejSZT890wA",
    annee: "2012",
    realisateur: "Denis Côté",
    acteurs: "Romane Bohringer, Pierette Robitaille, Marc-André Grondin",
    production: ["La Maison de Prod", "Metafilms"],
    distribution: ["Films Boutique", "Fun Films"],
    album: [
      "img/Films/Vic_plus_flo/V1.jpg",
      "img/Films/Vic_plus_flo/V2.jpg",
      "img/Films/Vic_plus_flo/V3.jpg",
      "img/Films/Vic_plus_flo/V5.jpg",
      "img/Films/Vic_plus_flo/V4.jpg",
      "img/Films/Vic_plus_flo/VIC.jpg"
    ]
  },
  { id: "laurence-anyways", 
    img: "img/Films/Laurence_Anyways/LAU.jpg", 
    titre: "Laurence Anyways",
    lien: "https://youtu.be/doh3M35m6z8",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2011",
    realisateur: "Xavier Dolan",
    concepteur: "Anne Pritchard",
    acteurs: "Melvil Poupaud, Suzanne Clément, Nathalie Baye",
    production: ["Lyla Films", "MK2"],
    distribution: ["Remstar", "MK2"],
    album: [
      "img/Films/Laurence_anyways/LAU.jpg",
      "img/Films/Laurence_anyways/LAU1.jpg",
      "img/Films/Laurence_anyways/LAU2.jpg",
      "img/Films/Laurence_anyways/LAU3.jpg",
      "img/Films/Laurence_anyways/LAU4.jpg",
      "img/Films/Laurence_anyways/LAU5.jpg",
      "img/Films/Laurence_anyways/LAU6.jpg",
      "img/Films/Laurence_anyways/LAU7.jpg",
      "img/Films/Laurence_anyways/LAU8.png"
    ]
  },
  { id: "good-neighbours", 
    img: "img/Films/Good_Neighbours/GOOD1.jpg", 
    titre: "Good Neighbours",
    lien: "https://youtu.be/OF0IF1zsStA",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2009",
    realisateur: "Jacob Tierney",
    concepteur: "Anne Pritchard",
    acteurs: "Jay Baruchel, Scott Speedman, Emily Hampshire",
    production: ["Kevin Tierney"],
    distribution: ["Alliance", "Park Ex Pictures"],
    album: [
      "img/Films/Good_neighbours/GOOD1.jpg",
      "img/Films/Good_neighbours/GOOD2.jpg",
      "img/Films/Good_neighbours/GOOD3.jpg"
    ]
  },
  { id: "the-trotsky", 
    img: "img/Films/The_Trotsky/TRO2.jpg", 
    titre: "The Trotsky",
    lien: "https://youtu.be/YS3LL_2i-fE",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2008",
    realisateur: "Jacob Tierney",
    concepteur: "Anne Pritchard",
    acteurs: "Jay Baruchel, Liane Balaban, Taylor Baruchel",
    production: ["Park Ex Pictures"],
    nominations: ["Nominee DGC Team Award : Outstanding Team Achievement in a Feature Film"],
    album: [
      "img/Films/The_trotsky/THE2.jpg",
      "img/Films/The_trotsky/THE1.jpg",
      "img/Films/The_trotsky/THE3.jpg"
    ]
  },
  { id: "punisher-war-zone", 
    img: "img/Films/Punisher/PUN.jpg", 
    titre: "Punisher: War Zone",
    lien: "https://youtu.be/liABMxEvPAc",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2007",
    realisateur: "Lexi Alexander",
    concepteur: "Andrew Neskorommy",
    acteurs: "Ray Stevenson, Dominic West, Julie Benz",
    production: ["Lions Gates", "Marvel"],
    distribution: ["Lions Gates"],
    album: [
      "img/Films/Punisher/PUN.jpg",
      "img/Films/Punisher/PUN3.jpg",
      "img/Films/Punisher/PUN4.jpg"
    ]
  },
  { id: "afterwards", 
    img: "img/Films/Afterwards/AF.jpg", 
    titre: "Afterwards",
    lien: "https://youtu.be/c2mYj_wbPwg",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2007",
    realisateur: "Gilles Bourdos",
    concepteur: "Anne Pritchard",
    acteurs: "Romain Duris, John Malkovich, Evangeline Lilly",
    production: ["Fidelité"],
    distribution: ["Christal Films"],
    album: [
      "img/Films/Afterwards/AF.jpg",
      "img/Films/Afterwards/AF2.jpg",
      "img/Films/Afterwards/AF4.jpg",
      "img/Films/Afterwards/AF6.jpg",
      "img/Films/Afterwards/AF7.jpg",
      "img/Films/Afterwards/AF8.jpg"
    ]
  },
  { id: "lucky-number-slevin", 
    img: "img/Films/Lucky_number/LUCK4.jpg", 
    titre: "Lucky Number Slevin",
    lien: "https://youtu.be/mGQmSCQrKKQ",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2004",
    realisateur: "Paul McGuigan",
    concepteur: "François Séguin",
    acteurs: "Josh Hartnett, Bruce Willis, Morgan Freeman, Lucy Liu",
    production: ["Ascendant Pictures", "FilmEngine"],
    distribution: ["MGM"],
    album: [
      "img/Films/Lucky_Number/LU1.jpg",
      "img/Films/Lucky_Number/LU2.jpg",
      "img/Films/Lucky_Number/LU3.jpg",
      "img/Films/Lucky_Number/LU4.jpg",
      "img/Films/Lucky_Number/LUCK2.jpg",
      "img/Films/Lucky_Number/LUCK4.jpg"
    ]
  },
  { id: "a-different-loyalty", 
    img: "img/Films/A_Different_Loyalty/ADIF4.jpg", 
    titre: "A Different Loyalty",
    lien: "https://youtu.be/-mEqmyJXj-M",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2003",
    realisateur: "Marek Kanievska",
    concepteur: "Anne Pritchard",
    acteurs: "Sharon Stone, Rupert Everett, Julian Wadham",
    production: ["Lions Gates"],
    distribution: ["Forum Films"],
    album: [
      "img/Films/A_Different_Loyalty/ADIF.jpg",
      "img/Films/A_Different_Loyalty/adif1.jpg",
      "img/Films/A_Different_Loyalty/adif2.jpg",
      "img/Films/A_Different_Loyalty/adif3.jpg",
      "img/Films/A_Different_Loyalty/adif4.jpg",
      "img/Films/A_Different_Loyalty/adif5.jpg"
    ]
  },
  { id: "les-immortels", 
    img: "img/Films/LES_Immortels/IMM1.jpg", 
    titre: "Les Immortels",
    lien: "", /* Lien manquant */
    annee: "2002",
    realisateur: "Paul Thinel",
    acteurs: "Jean Lapointe, Guillaume Lemay-Thivierge, Isabelle Lemme",
    production: ["ACPAV"],
    album: [
      "img/Films/Les_immortels/IMM1.jpg",
      "img/Films/Les_immortels/IMM2.jpg",
      "img/Films/Les_immortels/IMM3.jpg"
    ]
  },
  { id: "post-mortem", 
    img: "img/Films/Post_Mortem/POST3.jpg", 
    titre: "Post Mortem",
    lien: "https://www.youtube.com/watch?v=Rt0O6fOkOAk",
    annee: "1998",
    realisateur: "Louis Bélanger",
    acteurs: "Gabriel Arcand, Sylvie Moreau, Hélène Loiselle",
    production: ["Coop Video"],
    album: [
      "img/Films/Post_Mortem/POST1.jpg",
      "img/Films/Post_Mortem/POST2.jpg",
      "img/Films/Post_Mortem/POST3.jpg"
    ]
  },
  { id: "la-vie-est-laide", 
    img: "img/Films/Vie_est_laide/VIE3.jpg", 
    titre: "La Vie est Laide",
    lien: "https://youtu.be/7z0PawTSjWk",
    annee: "1998",
    realisateur: "Jean Leloup",
    acteurs: "Winston McQuade, Anna Papadakos",
    album: [
      "img/Films/Vie_est_laide/VIE.jpg",
      "img/Films/Vie_est_laide/VIE1.jpg",
      "img/Films/Vie_est_laide/VIE3.jpg",
      "img/Films/Vie_est_laide/VIE4.jpg",
      "img/Films/Vie_est_laide/VIE5.jpg",
      "img/Films/Vie_est_laide/VIE6.jpg"
    ]
  },
  { id: "lola", 
    img: "img/Films/Lola/Lo1.jpg", 
    titre: "Lola",
    lien: "",
    annee: "1996",
    realisateur: "Francis Leclerc",
    acteurs: "Claire Vezina",
    album: [
      "img/Films/Lola/LO1.jpg",
      "img/Films/Lola/LO2.jpg",
      "img/Films/Lola/LO3.jpg",
      "img/Films/Lola/LO4.jpg",
      "img/Films/Lola/LO5.jpg",
      "img/Films/Lola/LO6.jpg"
    ]
  }
];

const series = [
  { id: "oussekine", 
    img: "img/Series/Oussekine/OU1.jpg", 
    titre: "Oussekine",
    lien: "https://youtu.be/Fo2EoQ6HcXI",
    annee: "2021",
    realisateur: "Antoine Chevrolier",
    acteurs: "Hiam Abbass, Kad Merad, Sayyid El Alami, Tewfik Jallab, Mouna Soualem",
    production: ["Itinéraire Production"],
    distribution: ["Disney +", "UGC France"],
    album: [
      "img/Series/Oussekine/O1.jpg",
      "img/Series/Oussekine/O2.jpg",
      "img/Series/Oussekine/OU4.jpg",
      "img/Series/Oussekine/O4.jpg",
      "img/Series/Oussekine/O5.jpg",
      "img/Series/Oussekine/O6.jpg",
      "img/Series/Oussekine/OU5.jpg",
      "img/Series/Oussekine/OU1.jpg",
      "img/Series/Oussekine/OU2.jpg"
    ]
  },
  { id: "mytho", 
    img: "img/Series/Mytho/Mytho.jpg", 
    titre: "Mytho",
    lien: "https://youtu.be/UjtOktr1kmM",
    annee: "2019 - 2020",
    realisateur: "Fabrice Gobert",
    acteurs: "Marina Hands, Mathieu Demy, Yves Jacques",
    production: ["Unité de Production"],
    distribution: ["Netflix", "Arte"],
    album: [
      "img/Series/Mytho/Mytho2.jpg",
      "img/Series/Mytho/Mytho7.jpg",
      "img/Series/Mytho/Mytho6.jpg",
      "img/Series/Mytho/Mytho2.jpg",
      "img/Series/Mytho/Mytho1.jpg",
      "img/Series/Mytho/Mytho5.jpg"
    ]
  },
  { id: "ascension", 
    img: "img/Series/Ascension/AS.jpg", 
    titre: "Ascension",
    lien: "https://youtu.be/AJqQ4OdPcAo",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2014",
    realisateur: "Stephen Williams, Vincenzo Natali, Mairzee Almas, Rob Lieberman, Nicolas Copus",
    concepteur: "Jean-François Campeau",
    acteurs: "Tricia Helfer, Brian Van Holt, Gil Belows",
    production: ["Blumhouse", "Lions Gates"],
    distribution: ["Syfy"],
    album: [
      "img/Series/Ascension/AS.jpg",
      "img/Series/Ascension/AS3.jpg",
      "img/Series/Ascension/ASC5.jpg",
      "img/Series/Ascension/AS1.jpg",
      "img/Series/Ascension/as7.jpg",
      "img/Series/Ascension/AS2.jpg",
      "img/Series/Ascension/AS8.jpg",
      "img/Series/Ascension/AS10.jpg",
      "img/Series/Ascension/AS11.jpg"
    ]
  },
  { id: "being-human", 
    img: "img/Series/Being_Human/HUM1.jpg", 
    titre: "Being Human",
    lien: "https://youtu.be/aayb93qfXWQ",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2010",
    saison: "saison 1 - 13 épisodes",
    realisateur: "Adam Kane, Paolo Barzman, Charles Binamé, Éric Canuel, Jeremiah S. Chechik, Jerry Ciccoritti",
    concepteur: "Zoé Sakellaropoulo",
    acteurs: "Sam Witwer, Meaghan Rath, Sam Huntington",
    production: ["Muse Entertainment"],
    distribution: ["Syfy"],
    album: [
      "img/Series/Being_Human/HUM1.jpg",
      "img/Series/Being_Human/HUM2.jpg",
      "img/Series/Being_Human/HUM3.jpg",
      "img/Series/Being_Human/HUM5.jpg",
      "img/Series/Being_Human/HUM6.jpg",
      "img/Series/Being_Human/HUM4.jpg"
    ]
  },
  { id: "musee-eden", 
    img: "img/Series/Musee_Eden/MUS1.jpg", 
    titre: "Musée Eden",
    lien: "https://youtu.be/P2ItC0mogOE",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2009",
    saison: "saison 1 - 9 épisodes",
    realisateur: "Alain Desrochers",
    concepteur: "Dominique Desrochers",
    acteurs: "Mariloup Wolfe, Laurence Leboeuf, Éric Bruneau",
    production: ["Sovimage"],
    album: [
      "img/Series/Musee_Eden/MUS1.jpg",
      "img/Series/Musee_Eden/MUS2.jpg",
      "img/Series/Musee_Eden/MUS4.jpg"
    ]
  },
  { id: "nos-etes", 
    img: "img/Series/Nos_etes/ETE1.jpg", 
    titre: "Nos Étés",
    lien: "https://youtu.be/SgCaMOQPASg",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2004 - 2006",
    saison: "saison 1, 2 et 3 - 21 épisodes",
    realisateur: "Francis Leclercl, Lyne Charlebois, Alain Desrochers, Philipe Gagnon, Nicolas Monette",
    concepteur: "Jean Babin, Colombe Raby",
    acteurs: "Sophie Prégent, Fanny Malette, Jean-François Pichette",
    production: ["Cirrus Communications", "Duo Productions"],
    album: [
      "img/Series/Nos_etes/ETE4.jpg",
      "img/Series/Nos_etes/ETE2.jpg",
      "img/Series/Nos_etes/ETE3.jpg"
    ]
  },
  { id: "naked-josh", 
    img: "img/Series/Naked_Josh/JOSH1.jpg", 
    titre: "Naked Josh",
    lien: "",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2003",
    saison: "saison 1 - 8 épisodes",
    realisateur: "Paul Carrière",
    acteurs: "David Julian Hirsh, Sarah Smyth, Patricia McKenzie",
    production: ["Cirrus Communications"],
    album: [
      "img/Series/Naked_Josh/JOSH1.jpg",
      "img/Series/Naked_Josh/JOSH2.jpg",
      "img/Series/Naked_Josh/JOSH3.jpg"
    ]
  },
  { id: "asbestos", 
    img: "img/Series/Asbestos/ASB3.jpg", 
    titre: "Asbestos",
    lien: "",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2001",
    saison: "saison 1 - 6 épisodes",
    realisateur: "André Melançon",
    concepteur: "Louise Jobin",
    acteurs: "Stéphane Gagnon, Johanne-Marie Tremblay",
    production: ["Cirrus Communications"],
    album: [
      "img/Series/Asbestos/ASB1.jpg",
      "img/Series/Asbestos/ASB2.jpg",
      "img/Series/Asbestos/ASB3.jpg"
    ]
  },
  { id: "further-tales-of-the-city", 
    img: "img/Series/Further_Tales/FURT1.jpg", 
    titre: "Further Tales of the City",
    lien: "https://youtu.be/z4BB89u_cLc",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2000",
    saison: "saison 1 - 4 épisodes",
    realisateur: "Pierre Gang",
    concepteur: "Louise Jobin",
    acteurs: "Laura Linney, Olympia Dukakis, Paul Hopkins",
    production: ["Spectra"],
    album: [
      "img/Series/Further_Tales/FU.jpg",
      "img/Series/Further_Tales/FURT1.jpg",
      "img/Series/Further_Tales/FURT2.jpg"
    ]
  },
  { id: "l-ombre-de-l-epervier", 
    img: "img/Series/Ombre_de_l_Epervier/EPE.jpg", 
    titre: "L'Ombre de l'Épervier",
    lien: "https://youtu.be/oQ66JygmRMg",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "1999",
    saison: "saison 2 - 9 épisodes",
    realisateur: "Robert Favreau",
    concepteur: "Louise Jobin",
    acteurs: "Isabel Richer, Luc Picard",
    production: ["Verseau International"],
    album: [
      "img/Series/Ombre_de_l_Epervier/EPE.jpg",
      "img/Series/Ombre_de_l_Epervier/EPE2.jpg",
      "img/Series/Ombre_de_l_Epervier/EPE3.jpg"
    ]
  }
];

const pubs = [
  { id: "rona-2", 
    img: "img/Pubs/Rona2/DRESS1.jpg", 
    titre: "Rona",
    lien: "https://youtu.be/3bfBck_Ooew",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2019",
    compagnie: "Rona",
    realisateur: "Maxime Giroux",
    agence: "Sid Lee",
    album: [
      "img/Pubs/Rona2/DRESS1.jpg",
      "img/Pubs/Rona2/DRESS2.jpg",
      "img/Pubs/Rona2/DRESS3.jpg"
    ]
  },
  { id: "rona-1", 
    img: "img/Pubs/Rona1/SDB2.jpg", 
    titre: "Rona",
    lien: "https://www.youtube.com/watch?v=y8QX6c37dc0",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2019",
    compagnie: "Rona",
    realisateur: "Maxime Giroux",
    agence: "Sid Lee",
    album: [
      "img/Pubs/Rona1/sdb1.jpg",
      "img/Pubs/Rona1/sdb2.jpg",
      "img/Pubs/Rona1/sdb3.jpg"
    ]
  },
  { id: "bu", 
    img: "img/Pubs/Bu/BU.jpg", 
    titre: "Bù",
    lien: "",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2016",
    compagnie: "Bù",
    realisateur: "Maxime Giroux",
    agence: "DentsBos",
    album: [
      "img/Pubs/Bu/BU.jpg",
      "img/Pubs/Bu/BU.jpg",
      "img/Pubs/Bu/BU.jpg"
    ]
  },
  { id: "lotto-649-3", 
    img: "img/Pubs/Lotto3/BB8.jpg", 
    titre: "Lotto 6/49",
    lien: "https://youtu.be/4vMTwl1Twxk",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2015",
    compagnie: "Lotto 6/49",
    realisateur: "Maxime Giroux",
    agence: "Sid Lee",
    album: [
      "img/Pubs/Lotto3/BB7.jpg",
      "img/Pubs/Lotto3/BB8.jpg",
      "img/Pubs/Lotto3/BB9.jpg"
    ]
  },
  { id: "penningtons", 
    img: "img/Pubs/Penningtons/P4.jpg", 
    titre: "Penningtons",
    lien: "https://youtu.be/LppsvrkHfak",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2013",
    compagnie: "Penningtons",
    realisateur: "Maxime Giroux",
    agence: "LG2",
    album: [
      "img/Pubs/Penningtons/P1.jpg",
      "img/Pubs/Penningtons/P2.jpg",
      "img/Pubs/Penningtons/P3.jpg"
    ]
  },
  { id: "epargne-placements-quebec-3", 
    img: "img/Pubs/Epargne_Placements1/LAC6.jpg", 
    titre: "Épargne Placements Québec",
    lien: "https://youtu.be/5hMD5kGeXpc",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2015",
    compagnie: "Épargne Placements Québec",
    realisateur: "Maxime Giroux",
    agence: "Brad",
    album: [
      "img/Pubs/Epargne_Placements1/LAC5.jpg",
      "img/Pubs/Epargne_Placements1/LAC4.jpg",
      "img/Pubs/Epargne_Placements1/LAC3.jpg"
    ]
  },
  { id: "epargne-placements-quebec-2", 
    img: "img/Pubs/Epargne_Placements3/EPA3.jpg", 
    titre: "Épargne Placements Québec",
    lien: "https://www.youtube.com/watch?v=wNIvBr6YL8E",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2015",
    compagnie: "Épargne Placements Québec",
    realisateur: "Maxime Giroux",
    agence: "Brad",
    album: [
      "img/Pubs/Epargne_Placements3/EPA1.jpg",
      "img/Pubs/Epargne_Placements3/EPA2.jpg",
      "img/Pubs/Epargne_Placements3/EPA3.jpg"
    ]
  },
  { id: "epargne-placements-quebec-1", 
    img: "img/Pubs/Epargne_Placements2/Golf5.jpg", 
    titre: "Épargne Placements Québec",
    lien: "https://youtu.be/5hMD5kGeXpc",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2015",
    compagnie: "Épargne Placements Québec",
    realisateur: "Maxime Giroux",
    agence: "Brad",
    album: [
      "img/Pubs/Epargne_Placements2/GOLF1.jpg",
      "img/Pubs/Epargne_Placements2/GOLF2.jpg",
      "img/Pubs/Epargne_Placements2/GOLF4.jpg"
    ]
  },
  { id: "federation-des-producteurs-de-porcs-du-quebec-3", 
    img: "img/Pubs/Producteurs_Porcs1/PO10.jpg", 
    titre: "Fédérations des Producteurs de Porcs du Québec",
    lien: "https://youtu.be/k6_PFiprNrU",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2012",
    compagnie: "Fédérations des Producteurs de Porcs du Québec",
    realisateur: "Maxime Giroux",
    agence: "LG2",
    album: [
      "img/Pubs/Producteurs_Porcs1/PO7.jpg",
      "img/Pubs/Producteurs_Porcs1/PO8.jpg",
      "img/Pubs/Producteurs_Porcs1/PO10.jpg"
    ]
  },
  { id: "federation-des-producteurs-de-porcs-du-quebec-2", 
    img: "img/Pubs/Producteurs_Porcs2/PO5.jpg", 
    titre: "Fédérations des Producteurs de Porcs du Québec",
    lien: "https://youtu.be/YPENQ6B7O5w",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2012",
    compagnie: "Fédérations des Producteurs de Porcs du Québec",
    realisateur: "Maxime Giroux",
    agence: "LG2",
    album: [
      "img/Pubs/Producteurs_Porcs2/PO4.jpg",
      "img/Pubs/Producteurs_Porcs2/PO3.jpg",
      "img/Pubs/Producteurs_Porcs2/PO5.jpg"
    ]
  },
  { id: "federation-des-producteurs-de-porcs-du-quebec-1", 
    img: "img/Pubs/Producteurs_Porcs3/PO1.jpg", 
    titre: "Fédérations des Producteurs de Porcs du Québec",
    lien: "https://youtu.be/i6PxqrO93XQ",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2012",
    compagnie: "Fédérations des Producteurs de Porcs du Québec",
    realisateur: "Maxime Giroux",
    agence: "LG2",
    album: [
      "img/Pubs/Producteurs_Porcs3/PO11.jpg",
      "img/Pubs/Producteurs_Porcs3/PO13.jpg",
      "img/Pubs/Producteurs_Porcs3/PO1.jpg"
    ]
  },
  { id: "mc-donalds-london-olympics", 
    img: "img/Pubs/Mc_donalds/MC2.jpg", 
    titre: "Mc Donald's London Olympics",
    lien: "https://youtu.be/pQht2GLFoBM",
    annee: "2012",
    compagnie: "Mc Donald's",
    realisateur: "Benjamin Steiger Levine",
    agence: "Cossette",
    album: [
      "img/Pubs/MC_Donalds/MC4.jpg",
      "img/Pubs/MC_Donalds/MC5.jpg",
      "img/Pubs/MC_Donalds/MC2.jpg"
    ]
  },
  { id: "lotto-649-2", 
    img: "img/Pubs/Lotto2/6491.jpg", 
    titre: "Lotto 6/49",
    lien: "https://youtu.be/6rchuYBSkVA",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2012",
    compagnie: "Lotto 6/49",
    realisateur: "Maxime Giroux",
    agence: "Sid Lee",
    album: [
      "img/Pubs/Lotto2/6491.jpg",
      "img/Pubs/Lotto2/6493.jpg",
      "img/Pubs/Lotto2/6492.jpg"
    ]
  },
  { id: "lotto-649-1", 
    img: "img/Pubs/Lotto1/D2.jpg", 
    titre: "Lotto 6/49",
    lien: "https://youtu.be/HkMH3joH8Rg",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2012",
    compagnie: "Lotto 6/49",
    realisateur: "Maxime Giroux",
    agence: "Sid Lee",
    album: [
      "img/Pubs/Lotto1/D2.jpg",
      "img/Pubs/Lotto1/DE1.jpg",
      "img/Pubs/Lotto1/D3.jpg"
    ]
  },
  { id: "loto-quebec", 
    img: "img/Pubs/Loto_Quebec/LOT3.jpg", 
    titre: "Loto-Québec",
    lien: "https://youtu.be/w1-76f0rMAM",
    job: "DIRECTRICE ARTISTIQUE",
    annee: "2013",
    compagnie: "Loto-Québec",
    realisateur: "Maxime Giroux",
    agence: "LG2",
    album: [
      "img/Pubs/Loto_Quebec/LOT1.jpg",
      "img/Pubs/Loto_Quebec/LOT2.jpg",
      "img/Pubs/Loto_Quebec/LOT3.jpg",
      "img/Pubs/Loto_Quebec/LOT4.jpg",
      "img/Pubs/Loto_Quebec/LOT5.jpg",
      "img/Pubs/Loto_Quebec/LOT6.jpg"
    ]
  }
];

export {films, series, pubs};