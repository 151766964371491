import React from "react";
import { useParams } from "react-router-dom";
import Production from "../components/Production";
import { films, series, pubs } from "../productions";

function ajouterProduction(production) {
  return (
    <Production 
      key={production.id}
      id={production.id}
      img={production.img}
      titre={production.titre}
      lien={production.lien}
      annee={production.annee}
      realisateur={production.realisateur}
      acteurs={production.acteurs}
      production={production.production}
      distribution={production.distribution}
      nominations={production.nominations}
      job={production.job}
      concepteur={production.concepteur}
      saison={production.saison}
      compagnie={production.compagnie}
      agence={production.agence}
      className="production-item"
      classTitre="titreAllProduction"
    />
  );
}

function Productions() {
  const { category } = useParams();
  let selectedProductions;

  if (category) {
    switch (category) {
      case 'films':
        selectedProductions = films;
        break;
      case 'series':
        selectedProductions = series;
        break;
      case 'publicites':
        selectedProductions = pubs;
        break;
      default:
        selectedProductions = [...films, ...series, ...pubs];
        break;
    }
  } else {
    selectedProductions = [...films, ...series, ...pubs];
  }

  return (
    <div className="production-grid">
      {selectedProductions.map(ajouterProduction)}
    </div> 
  );
}

export default Productions;
