const home = [
  { id: "simple-comme-sylvain", img: "img/Films/Simple_comme_Sylvain/SCS1.jpg", titre: "Simple comme Sylvain" },
  { id: "tu-ne-sauras-jamais", img: "img/Films/tu_ne_sauras_jamais/TNSJ1.jpg", titre: "Tu ne sauras jamais" },
  { id: "oussekine", img: "img/Series/Oussekine/OU8.jpg", titre: "Oussekine" },
  { id: "babysitter", img: "img/Films/Babysitter/BB5.jpg", titre: "Babysitter" },
  { id: "mytho", img: "img/Series/Mytho/Mytho.jpg", titre: "Mytho" },
  { id: "matthias-et-maxime", img: "img/Films/Matthias_Maxime/MM.jpg", titre: "Matthias & Maxime" }, 
  { id: "the-life-and-death-of-john-f-donovan", img: "img/Films/John_F_Donovan/JOHN.jpg", titre: "The Death and Life of John F. Donovan" }, 
  { id: "hello", img: "img/Films/Hello/HELLO.jpg", titre: "Hello" },
  { id: "juste-la-fin-du-monde", img: "img/Films/Juste_la_fin_du_monde/END.jpg", titre: "Juste la Fin du Monde" },
  { id: "ascension", img: "img/Series/Ascension/AS.jpg", titre: "Ascension", job: "Directrice artistique" },
  { id: "mommy", img: "img/Films/Mommy/MOM.jpg", titre: "Mommy" }, 
  { id: "tom-a-la-ferme", img: "img/Films/Tom_a_la_ferme/TOM.jpg", titre: "Tom à la ferme" }, 
  { id: "vic-plus-flo-ont-vu-un-ours", img: "img/Films/Vic_plus_Flo/VIC.jpg", titre: "Vic et Flo ont vu un ours" },
  { id: "laurence-anyways", img: "img/Films/Laurence_Anyways/LAU.jpg", titre: "Laurence Anyways", job: "Directrice artistique" },
  { id: "punisher-war-zone", img: "img/Films/Punisher/PUN.jpg", titre: "Punisher: War Zone", job: "Directrice artistique" },
  { id: "afterwards", img: "img/Films/Afterwards/AF.jpg", titre: "Afterwards", job: "Directrice artistique" },
];

export default home;